export const executiveMembers = [
  {
    name: 'Mohsin Patwary, Ph.D.',
    role: 'President',
    level: 'warning',
    designation: 'Professor of Biology, City University of New York, USA',
    image: '/assets/images/profile/president.jpg',
  },
  {
    name: 'Sheikh Abdus Selim, Ph.D.',
    role: 'President Elect',
    level: 'warning',
    designation: 'Director, PHL Associates, Inc., Davis, CA, USA',
    image: '/assets/images/profile/president-elect.jpeg',
  },
  {
    name: 'Anwar Huq, Ph.D.',
    role: 'Past President',
    level: 'warning',
    designation: 'Professor, University of Maryland College Park, MD, USA',
    image: '/assets/images/profile/president-past.jpg',
  },
  {
    name: 'Mahmuda Yasmin, Ph.D.',
    role: 'Vice President',
    level: 'info',
    designation: 'Professor of Microbiology, Dhaka University, Bangladesh',
    image: '/assets/images/profile/vp1.jpg',
  },
  {
    name: 'Syed Muniruzzaman, Ph.D.',
    role: 'Vice President',
    level: 'info',
    designation: 'Associate Professor, Xavier University of Louisiana, USA',
    image: '/assets/images/profile/vp2.jpg',
  },
  {
    name: 'Monjurul Karim, Ph.D.',
    role: 'Vice President',
    level: 'info',
    designation: 'Professor of Microbiology, Dhaka University, Bangladesh',
    image: '/assets/images/profile/vp3.jpg',
  },
  {
    name: 'Mizanur Rahman, Ph.D.',
    role: 'General Secretary',
    level: 'success',
    designation: 'Regulatory Scientist, USA',
    image: '/assets/images/profile/gs.jpg',
  },
  {
    name: 'Fokrul Monowar Hossain, Ph.D.',
    role: 'Joint Secretary',
    level: 'success',
    designation: 'Assistant Professor, Louisiana State University, USA',
    image: '/assets/images/profile/js.jpeg',
  },
  {
    name: 'Sangita Ahmed, Ph.D.',
    role: 'Organizing Secretary',
    level: 'success',
    variant: 'outlined',
    designation: 'Professor of Microbiology, Dhaka University, Bangladesh',
    image: '/assets/images/profile/os1.jpeg',
  },
  {
    name: 'Aftabul Haque, Ph.D.',
    role: 'Organizing Secretary',
    level: 'success',
    variant: 'outlined',
    designation: 'Principal Scientist, Biogen, USA',
    image: '/assets/images/profile/os2.jpg',
  },
  {
    name: 'Md Niaz Rahim, PhD',
    role: 'Organizing Secretary',
    level: 'success',
    variant: 'outlined',
    designation: 'Chief Scientific Officer, Government of Manitoba, Canada',
    image: '/assets/images/profile/os3.jpeg',
  },
  {
    name: 'Hossain M. Azam, Ph.D.',
    role: 'Treasurer',
    level: 'success',
    variant: 'outlined',
    designation: 'Associate Professor, University of the District of Columbia, USA',
    image: '/assets/images/profile/tr.jpg',
  },
  {
    name: 'Salma Akhter, Ph.D.',
    role: 'Assistant Treasurer',
    level: 'success',
    variant: 'outlined',
    designation: 'Associate Professor, Jahangirnagar University, Dhaka, Bangladesh',
    image: '/assets/images/profile/atr.jpg',
  },
  {
    name: 'Habibul Bakth, Ph.D.',
    role: 'Media & Publication Secretary',
    level: 'success',
    variant: 'outlined',
    designation: 'Assistant Professor and Chair, Hilbert College, New York, USA',
    image: '/assets/images/profile/mps.jpeg',
  },
  {
    name: 'Belal Hossain, M.S.',
    role: 'Public Affairs Secretary',
    level: 'success',
    variant: 'outlined',
    designation:
      'Leader Clinical Research, Interior Health, Kelowna General Hospital, British Columbia, Canada',
    image: '/assets/images/profile/pas.jpg',
  },
  {
    name: 'Fahmina Jahan Moona, M.S.',
    role: 'Student Affairs Secretary',
    level: 'success',
    variant: 'outlined',
    designation: 'Dalton Pharma Services, Canada',
    image: '/assets/images/profile/sas.jpeg',
  },
  {
    name: 'Syeda Antara Shabnam, M.S.',
    role: 'Administrative Secretary',
    level: 'success',
    variant: 'outlined',
    designation: 'Scientist, Cambrex Corporation, MA, USA',
    image: '/assets/images/profile/as.jpg',
  },
  {
    name: 'Anwar Huq, Ph.D.',
    role: 'Board of Director',
    level: 'info',
    variant: 'outlined',
    designation: 'Professor, University of Maryland College Park, MD, USA',
    image: '/assets/images/profile/president-past.jpg',
  },
  {
    name: 'Nayema Bulbul, Ph.D.',
    role: 'Board of Director',
    level: 'info',
    variant: 'outlined',
    designation: 'North South University, Dhaka, Bangladesh',
    image: '/assets/images/profile/dir1.jpg',
  },
  {
    name: 'Senjuti Saha, Ph.D.',
    role: 'Board of Director',
    level: 'info',
    variant: 'outlined',
    designation: 'Director, Child Health Research Foundation, Dhaka, Bangladesh',
    image: '/assets/images/profile/dir2.jpg',
  },
  {
    name: 'Ismat Azmi, M.S.',
    role: 'Board of Director',
    level: 'info',
    variant: 'outlined',
    designation: 'Public Health, Government of Ontario, Ontario, Canada',
    // image: '/assets/images/profile/dir3.jpg',
  },
  {
    name: 'Nadia Deen, Ph.D.',
    role: 'Board of Director',
    level: 'info',
    variant: 'outlined',
    designation: 'Associate Professor, BRAC University, Dhaka, Bangladesh',
    image: '/assets/images/profile/dir4.JPG',
  },
  // {
  //   name: 'Dr Sharmin Sultana',
  //   role: 'Organizing Secretary',
  //   level: 'success',
  //   variant: 'outlined',
  //   designation:
  //     'Associate Professor, Department of Microbiology, University of Chittagong, Bangladesh',
  //   image: '/assets/images/profile/ex-os3.jpg',
  // },
  // {
  //   name: 'Dr Ferdaus Hassan',
  //   role: 'Board of Director',
  //   level: 'info',
  //   variant: 'outlined',
  //   designation: 'Medical Science Liaison, Sanofi-Pasteur, USA',
  //   image: '/assets/images/profile/ex-dir1.jpg',
  // },
  // {
  //   name: 'Dr Nur A Hasan',
  //   role: 'Board of Director',
  //   level: 'info',
  //   variant: 'outlined',
  //   designation: 'CEO, EzBIOME Inc., USA',
  //   image: '/assets/images/profile/ex-dir2.jpg',
  // },
  // {
  //   name: 'Dr Siraje Arif Mahmud',
  //   role: 'Board of Director',
  //   level: 'info',
  //   variant: 'outlined',
  //   designation: 'Post Doctoral Fellow, Department of Biology, University of Texas-Arlington, USA',
  //   image: '/assets/images/profile/ex-dir5.jpg',
  // },
];

export const advisoryMembers = [
  {
    name: 'Selina Parveen, Ph.D.',
    designation: 'Professor, University of Maryland Eastern Shore, Maryland, USA',
    image: '/assets/images/profile/adv-1.jpg',
  },
  {
    name: 'Afzal Chowdhury, Ph.D',
    designation: 'Scientific Advisor, McCarter & English, LLP, Washington DC, USA',
    image: '/assets/images/profile/adv-2.jpg',
  },
  {
    name: 'Jafrul Hasan, Ph.D.',
    designation: 'OPPT Quality Assurance Manager and Senior Advisor, OPPT, EPA, USA',
    image: '/assets/images/profile/adv-3.jpg',
  },
  {
    name: 'Munirul Alam, Ph.D.',
    designation:
      'Senior Scientist, ICDDR, B. Head Molecular Ecology & Metagenomic Laboratory, Dhaka, Bangladesh',
    image: '/assets/images/profile/adv-4.jpeg',
  },
  {
    name: 'Md. Anwar Hussain, Ph.D.',
    designation:
      'Professor & Vice-Chancellor, Jashore University of Science and Technology, Jashore, Bangladesh',
    // image: '/assets/images/profile/adv-5.jpeg',
  },
  {
    name: 'Nur A. Hasan, Ph.D.',
    designation: 'President and CEO, EZ Biome, Maryland, USA',
    image: '/assets/images/profile/adv-6.jpeg',
  },
  {
    name: 'Abul Kalam Azad, Ph.D.',
    designation: 'Professor, Shahjalal University of Science and Technology, Sylhet, Bangladesh',
    image: '/assets/images/profile/adv-7.jpg',
  },
  {
    name: 'Md Mozammel Hoq',
    designation: 'Former Professor, Department of Microbiology, University of Dhaka, Bangladesh',
    image: '/assets/images/profile/adv-8.jpg',
  },
  // {
  //   name: 'Nadia Deen, Ph.D.',
  //   designation: 'Associate Professor, BRAC University, Dhaka, Bangladesh',
  //   image: '/assets/images/profile/dir4.JPG',
  // },
  // {
  //   name: 'Dr K. M. Sultanul Aziz',
  //   designation:
  //     'Ex Division Director, ICDDR, B and Professor, Jahangir Nagar University, Bangladesh',
  //   image: '/assets/images/profile/elec1.jpg',
  // },
  // {
  //   name: 'Dr. Saleh Mostafa Kamal',
  //   designation: 'CEO and Managing Director Bangladesh Biman, Dhaka, Bangladesh',
  //   image: '/assets/images/profile/ex-adv-4.jpg',
  // },
  // {
  //   name: 'Dr Afzal Chowdhury',
  //   designation: 'Scientific Advisor, Perkins Coie LLP Washington DC, USA',
  //   image: '/assets/images/profile/adv-2.jpg',
  // },
  // {
  //   name: 'Mr. Md Nawabur Rahman',
  //   designation:
  //     'Executive Director of Technical Operations Square Pharmaceuticals, Dhaka, Bangladesh Former employee, Apotex, Canada, Member PDA, US;  AOTS; PGA; BSM',
  //   image: '/assets/images/profile/ex-adv-7.jpg',
  // },
  // {
  //   name: 'Samir K Saha, PhD, FAAM, FRCPath',
  //   designation:
  //     'Executive Director Child Health Research Foundation & Professor and Head Department of Microbiology Bangladesh Shishu Hospital and Institute Dhaka, Bangladesh',
  //   image: '/assets/images/profile/ex-adv-8.jpg',
  // },
];

export const elecCommission = [
  {
    name: 'Dr K. M. Sultanul Aziz',
    role: 'Chief Election Commissioner',
    level: 'warning',
    designation: 'Scientific Advisor, Perkins Coie LLP Washington DC, USA',
    image: '/assets/images/profile/elec1.jpg',
  },
  {
    name: 'Prof Dr. Muhammad J. Habib',
    role: 'Election Commissioner',
    level: 'info',
    designation:
      'Associate Dean and Professor, College of Pharmacy, Howard University, Washington DC, USA',
    image: '/assets/images/profile/elec2.jpg',
  },
  {
    name: 'Dr. Abdul Quayum Khan',
    role: 'Election Commissioner',
    level: 'info',
    designation: 'Diagnostic Microbiologist; Adjunct Professor, Montgomery College, Maryland, USA',
    image: '/assets/images/profile/elec3.jpg',
  },
];
